import * as React from "react";
import Layout from "../components/layout"

const ContactPage = ({}) => {
    return (
        <Layout pageTitle="Contact">
            <h1>Contact us</h1>
            <p>Email us at <a href="mailto:contact@better-games.org">contact@better-games.org</a></p>
            <p>Or join our <a href="https://discord.gg/nupuTEw">discord server</a></p>
        </Layout>
    )
}

export default ContactPage